<template>
  <PageLayout>
    <template #header>
      <k-title>{{ candidate.name }}</k-title>
    </template>
    <template
      v-if="candidate"
      #default
    >
      <k-display-group language-prefix="candidate.fields">
        <v-row>
          <v-col>
            <k-dossier>
              <k-subtitle>{{ $t('candidate.functionProfile') }}</k-subtitle>
              <k-text-display
                v-if="candidate.sector"
                field="sector"
              >
                {{ candidate.sector.name }}
              </k-text-display>
              <k-text-display field="position">
                <div v-html="positions" />
              </k-text-display>
              <k-text-display field="candidate.fields.branch">
                <div v-html="branches" />
              </k-text-display>
            </k-dossier>
          </v-col>
          <v-col>
            <k-dossier>
              <k-subtitle>{{ $t('candidate.transport') }}</k-subtitle>
              <v-row class="my-n3">
                <v-col>
                  <k-text-display field="maxTravelingTime">
                    {{ candidate.maxTravelingTime }}
                  </k-text-display>
                </v-col>
                <v-col>
                  <v-row>
                    <v-icon
                      :color="candidate.transportationCar ? 'grey darken-2' : 'grey lighten-2'"
                      class="ma-2"
                      large
                    >
                      $car
                    </v-icon>
                    <span class="my-auto">{{ $t('candidate.fields.transportationCar') }}</span>
                  </v-row>
                  <v-row>
                    <v-icon
                      :color="candidate.transportationPublic ? 'grey darken-2' : 'grey lighten-2'"
                      class="ma-2"
                      large
                    >
                      $train
                    </v-icon>
                    <span class="my-auto">{{ $t('candidate.fields.transportationPublic') }}</span>
                  </v-row>
                </v-col>
              </v-row>
            </k-dossier>
          </v-col>
        </v-row>
      </k-display-group>
    </template>
  </PageLayout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import KTitle from '@/components/layout/KTitle.vue';
import KDossier from '@/components/layout/KDossier.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';

export default {
  name: 'CandidateWishes',
  components: {
    KSubtitle,
    KDossier,
    KTitle,
    KDisplayGroup,
    PageLayout,
    KTextDisplay,
  },
  props: {
    candidate: {
      type: Object,
    },
    getCandidate: {
      type: Function,
    },
  },
  computed: {
    positions() {
      return this.candidate.positions.map((position) => position.name).join('<br />');
    },
    branches() {
      return this.candidate.branches.map((branch) => branch.name).join('<br />');
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'candidate.index' },
        text: this.$tc('candidate.title', 2),
      },
      {
        exact: true,
        to: { name: 'candidate.show' },
        text: this.candidate.name,
      }],
    );
  },
};
</script>
